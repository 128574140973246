import { Provider } from 'mobx-react';
import React from 'react';
import { api, CopyRightFooter } from '../../utils';
import styles from './index.less';
import type { Props } from './interface';
import { Store } from './store';

export default class Index extends React.Component<Props> {
  public store = new Store(this.props);

  render(): JSX.Element {
    const { goToLogin, logoImg, logoText } = this.store;
    return (
      <Provider store={this.store}>
        <div className={styles.frame}>
          <div className={styles.header}>
            <div>
              <img
                onClick={goToLogin}
                src={logoImg || `${api.oss}/images/logo.png`}
              />
              <span className={styles.title}>
                {logoText || '专业服装供应链服务商'}
              </span>
            </div>
            <span
              className={styles.login}
              onClick={goToLogin}
            >
              登录
              <img
                className={styles.loginIcon}
                src={`${api.oss}/images/arrowRight.png`}
              />
            </span>
          </div>
          {/* <Content/> */}
          {this.props.children}
          <CopyRightFooter/>
        </div>
      </Provider>
    );
  }
}
