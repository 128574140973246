import React from 'react';
import styles from './index.less';

export const CopyRightFooter = (props: { className?: string; textClassName?: string; }) => {
  const { className, textClassName } = props;
  return (
    <div className={`${styles.footer} ${className}`}>
      江苏衫数科技集团有限公司© 版权所有 |
      {' '}
      {window.location.href.indexOf('runscm.com') === -1 ? (
        <a
          className={`${styles.link} ${textClassName}`}
          href="https://beian.miit.gov.cn"
          rel="noreferrer"
          target="_blank"
        >
          苏ICP备2022001362号-1
        </a>
      ) : (
        <a
          className={`${styles.link} ${textClassName}`}
          href="https://beian.miit.gov.cn"
          rel="noreferrer"
          target="_blank"
        >
          苏ICP备2022001362号-7
        </a>
      ) }
    </div>
  );
};

