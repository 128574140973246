import { Button } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { api } from '../../../utils/api';
import type { Props } from '../interface';
import styles from './index.less';

@inject('store')
@observer
export default class ResultMessage extends React.Component<Props> {
  render(): JSX.Element {
    const { goToLogin } = this.props.store;

    return (
      <div className={styles.resultMessage}>
        <img
          className={styles.choice}
          src={`${api.oss}/images/successIcon.png`}
        />
        <div className={styles.title}>
          注册审核中
        </div>
        <div className={styles.tips}>
          预计24小时内完成审核
        </div>
        <div className={styles.concat}>
          如有问题请联系客户经理
        </div>
        <div className={styles.concat}>
          王经理  18200902220
        </div>
        <Button
          className={styles.primaryButton}
          onClick={goToLogin}
        >
          完成
        </Button>
      </div>
    );
  }
}
