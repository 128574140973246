/**
 * 包含字母和数字且长度在8到16位的密码：
 */
export const passwordReg = /^(?=.*\d+)(?=.*[a-zA-Z]+)[0-9a-zA-Z~!@#$^*_]{8,18}$/;

/**
    * 大陆手机号码11位数，匹配格式：前三位固定格式+后8位任意数
    * 此方法中前三位格式有：
    * 13+任意数
    * 145,147,149
    * 15+除4的任意数(不要写^4，这样的话字母也会被认为是正确的)
    * 166
    * 17+3,5,6,7,8
    * 18+任意数
    * 198,199
    *
    * 香港手机号码8位数，5|6|8|9开头+7位任意数
    * 前端手机号格式参考了后端
    */
export const phoneReg = /^((13[0-9])|(14[0,1,4-9])|(15[0-3,5-9])|(16[2,5,6,7])|(17[0-8])|(18[0-9])|(19[0-3,5-9]))\d{8}$|^(5|6|8|9)\d{7}$/;

/**
 * 格式: name@domin
 * 以大写字母[A-Z]、小写字母[a-z]、数字[0-9]、下滑线[_]、减号[-]及点号[.]开头，并需要重复一次至多次[+]。
 * 中间必须包括@符号。
 * @之后需要连接大写字母[A-Z]、小写字母[a-z]、数字[0-9]、下滑线[_]、减号[-]及点号[.]，并需要重复一次至多次[+]。
 * 必须是点号[.]连接2至6位的大小写字母[A-Za-z]{2,6}。
 */
export const emailReg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;

