import { getStaticResourceUrl } from 'egenie-common';

export const api = {
  // 静态资源
  oss: getStaticResourceUrl('pc/ts/egenie-common'),

  // 登录
  login: '/api/iam/login', // 登录
  getAuthImage: '/api/iac/authImage/anon/getAuthImage', // 获取图片验证码

  // 字典
  findDict: '/api/iac/anon/dict', // {codeType}

  // 注册
  marketList: '/api/gim/rest/anon/market/findAllMarket', // 市场列表
  register: '/api/iac/user/anon/register', // 注册新用户
  registerRetailer: '/api/iac/register/anon/retailer', // 注册零售商
  registerSupplier: '/api/iac/register/anon/supplier', // 注册供应商
  findMarketCity: '/api/gim/rest/anon/market/findMarketCity', // 查城市
  findByCityIds: '/api/gim/rest/anon/market/findByCityIds', // 根据城市查市场
  findFloor: '/api/gim/anon/floor/findFloor', // 查楼层
  queryRepeatShop: '/api/pos/baseinfo/rest/shop/anon/queryRepeatShop', // 检查档口好是否重复
  findOneLevel: '/api/gim/category/anon/findOneLevel', // 获取主营分类【一级】
  userCheck: '/api/iac/user/check', // 验证手机号是否存在
  findProvince: '/api/iac/register/anon/province', // 省
  findCityByProvince: '/api/iac/register/anon/city', // 市
  findDistrictByCity: '/api/iac/register/anon/district', // 区

  // 忘记密码
  checkUserName: '/api/iac/user/anon/findByUsername', // 验证用户是否存在
  sendCode: '/api/iac/validCode/anon/send', // 发送验证码
  validateCode: '/api/iac/validCode/anon/password/reset/validate', // 验证手机号验证码
  newPassword: '/api/iac/user/anon/password/reset', // 修改密码
  sendIAMCode: '/api/iam/validCode/anon/send', // 发送验证码接口
  loginByIam: '/api/iam/sms/login',
};
