import type { MenuDataItem } from 'egenie-common';
import React from 'react';
import { FindPassword } from './pages/loginForm/findPassword/index';
import { Registry } from './pages/loginForm/registry/index';

export const routes: MenuDataItem[] = [
  {
    children: [
      {
        exact: true,
        path: '/',
        redirect: '/egenie-ts-iac/login',
      },
      {
        path: '/egenie-ts-iac/login',
        title: '登录',
        exact: true,
        component: React.lazy(() => import('./pages/erpLogin/index')),
      },
      {
        path: '/egenie-ts-iac/registry',
        title: '注册',
        exact: true,
        component: Registry,
      },
      {
        path: '/egenie-ts-iac/findPassword',
        title: '查找密码',
        exact: true,
        component: FindPassword,

        logoText: '专业服装供应链服务商',
      },
      {
        path: '/egenie-ts-iac/pgmsLogin',
        title: '登录',
        exact: true,
        component: React.lazy(() => import('./pages/pgmsLogin/index')),
      }, // 拿货精灵登录页
      {
        path: '/egenie-ts-iac/galleryLogin',
        title: '登录',
        exact: true,
        component: React.lazy(() => import('./pages/galleryLogin/index')),
      }, // 展厅登录页
      {
        path: '/egenie-ts-iac/cloudWmsLogin',
        title: '登录',
        exact: true,
        component: React.lazy(() => import('./pages/cloudWmsLogin/index')),
      }, // 云仓登录页
      {
        path: '/egenie-ts-iac/bossLogin',
        title: '登录',
        exact: true,
        component: React.lazy(() => import('./pages/bossLogin/index')),
      }, // boss登录页
      {
        path: '/egenie-ts-iac/anchorLogin',
        title: '直播盈-一样的直播，更高的收益!',
        exact: true,
        component: React.lazy(() => import('./pages/anchorLogin/index')),
      }, // 组货服务商
    ],
  },
];
