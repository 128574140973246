import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Checkbox, Row, Col, Cascader, Tooltip, Tabs } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { Props } from '../interface';
import { Address } from './address';
import styles from './index.less';
import { protocol } from './protocol';
import { StallFormList } from './stallFormList';

@inject('store')
@observer
export default class PosFormInfo extends React.Component<Props> {
  componentDidMount(): void {
    const { getMarketList, getCity } = this.props.store;
    getMarketList();
    getCity();
  }

  // 验证码
  public getCode = (): JSX.Element => {
    const { isSendCode, showImageCode, countDown } = this.props.store;
    return (

      // 此Id提供给图形验证码
      <Row id="registry">
        <Col span={13}>
          <Input placeholder="验证码"/>
        </Col>
        <Col span={10}>
          <Button
            className={styles.smscode}
            disabled={isSendCode}
            onClick={showImageCode.bind(this, 'registry', 134, -192)}
            type="primary"
          >
            {isSendCode ? `${countDown}S后重新获取` : '获取验证码'}
          </Button>
        </Col>
      </Row>
    );
  };

  public formList = (POSRegistryFormData): JSX.Element[] => {
    const { systemType } = this.props.store;
    let result: JSX.Element[] = [];
    const formItemKeys = Object.keys(POSRegistryFormData);

    result = formItemKeys.map((keyName: string) => {
      const data = POSRegistryFormData[keyName];
      if (!(!data.systemType || data.systemType.includes(systemType))) {
        return null;
      }
      let itemEle = null;
      let label = '';
      let placeholder = '';
      let rules = data?.rules;
      if (data.differentLabel && typeof data.label === 'object') {
        label = data.label[systemType];
        placeholder = data.placeholder[systemType];
        rules = rules?.map((_item) => {
          return {
            ..._item,
            message: data.placeholder[systemType],
          };
        });
      } else {
        label = data.label;
        placeholder = data.placeholder;
      }
      if (data.type === 'select') {
        itemEle = (
          <Select
            mode={data.mode}
            placeholder={data.placeholder}
            showArrow
          >
            {data.options.map((item) => (
              <Select.Option
                key={item.value}
                value={item.value}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        );
      } else if (data.type === 'input') {
        itemEle = keyName === 'smsCode' ? this.getCode() : (
          data.prop === 'password'
            ? (
              <Input.Password
                autoComplete="new-password"
                placeholder={placeholder}
                visibilityToggle={false}
              />
            )
            : (
              <Input
                autoComplete="off"
                placeholder={placeholder}
                suffix={data.differentLabel && data.tooltip[systemType] ? (
                  <Tooltip title={data.tooltip[systemType]}>
                    <QuestionCircleOutlined
                      className={styles.suffix}
                      style={{ fontSize: 14 }}
                    />
                  </Tooltip>
                ) : undefined}
                type={data.prop}
              />
            )
        );
      } else if (data.type === 'address') {
        itemEle = (
          <Address/>
        );
      }
      return (
        <Form.Item
          className={!data.required && 'norequired'}
          extra={data.help}
          key={keyName}
          label={label}
          name={keyName}
          required={data.required}
          rules={rules}
        >
          {itemEle}
        </Form.Item>
      );
    });
    return result;
  };

  render(): JSX.Element {
    const { systemType, registryformRef, handleFieldChange, registryDisabled, goBackChoice, handleRegistrySubmit, changePosTab, POSRegistryFormData } = this.props.store;
    const layout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    return (
      <div className={styles.formInfo}>
        <div className={styles.title}>
          填写
          {systemType.includes('POS') ? systemType.split('-')[0] : systemType}
          系统注册信息
        </div>
        <div className={styles.content}>
          <div className={styles.formWrapper}>
            {
              systemType.includes('POS') && (
                <Tabs
                  activeKey={systemType}
                  onChange={changePosTab}
                >
                  <Tabs.TabPane
                    key="POS-STALL"
                    tab="档口"
                  />
                  {/* <Tabs.TabPane
                    key="POS-STORE"
                    tab="门店"
                  /> */}
                  <Tabs.TabPane
                    key="POS-FACTORY"
                    tab="工厂"
                  />
                </Tabs>
              )
            }
            <Form
              className={styles.posForm}
              {...layout}
              colon={false}
              onFieldsChange={handleFieldChange}
              ref={registryformRef}
              validateTrigger={[
                'onBlur',
                'onChange',
              ]}
            >
              {POSRegistryFormData.map((item) => {
                return (
                  <>
                    {item.title && (
                      <div className={styles.formPartTitle}>
                        <span className={styles.formPartIcon}/>
                        {item.title}
                      </div>
                    )}
                    {this.formList(item.children)}
                  </>
                );
              })}
              {systemType === 'POS-STALL' && <StallFormList store={this.props.store}/>}
              <Form.Item
                className="norequired"
                label={' '}
                labelCol={{ span: 6 }}
                name="agreement"
                rules={[{ validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('需要同意协议'))) }]}
                valuePropName="checked"
                wrapperCol={{ span: 18 }}
              >
                <Checkbox className={styles.agreeCheckbox}>
                  <div className={styles.agreeProtocol}>
                    <span>
                      我同意
                    </span>
                    <span className={styles.protocolText}>
                      《e精灵 SaaS
                      {' '}
                      {systemType.includes('POS') ? systemType.split('-')[0] : systemType}
                      申请帐户协议》
                    </span>
                  </div>
                </Checkbox>
              </Form.Item>
            </Form>
            <div className={styles.operation}>
              <Button
                className={styles.posFormPreviousStep}
                onClick={goBackChoice}
                type="default"
              >
                上一步
              </Button>
              <Button
                className={styles.posFormSubmit}
                disabled={registryDisabled}
                htmlType="submit"
                onClick={handleRegistrySubmit}
                type="primary"
              >
                注册
              </Button>
            </div>
          </div>
          <div className={styles.protocol}>
            <div className={styles.title}>
              e精灵 SaaS
              {' '}
              {systemType.includes('POS') ? systemType.split('-')[0] : systemType}
              申请帐户协议
            </div>
            <div className={styles.content}>
              {protocol(systemType.includes('POS') ? systemType.split('-')[0] : systemType)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
