import { Cascader, Input } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { api } from '../../../utils';
import type { City, DetailAddress, District, Province, SelectOption } from '../interface';
import styles from './index.less';

interface Props {
  onChange?: (area: DetailAddress) => void;
}

const Address = observer((props: Props) => {
  const [
    address,
    setAddress,
  ] = useState({
    provinceId: 0,
    cityId: 0,
    districtId: 0,
  });

  const [
    data,
    setData,
  ] = useState([]);

  const [
    detail,
    setDetail,
  ] = useState(''); // 详细地址

  useEffect(() => {
    getProvinceList();
  }, []);

  const getProvinceList = async(): Promise<void> => {
    const res: BaseData<Province[]> = await request({ url: api.findProvince });
    const province = res.data?.map((item) => ({
      label: item.name,
      value: item.id,
      isLeaf: false,
    }));
    setData(province);
  };

  // 处理省市区数据
  const loadAddressData = (selectedOptions: SelectOption[]): void => {
    const { length } = selectedOptions;
    if (length === 1) {
      // 一级联动
      const targetOption = selectedOptions[0];
      targetOption.loading = true;
      request({ url: `${api.findCityByProvince}?id=${[targetOption.value]}` }).then((res: BaseData<City[]>) => {
        const childData = res.data.map((item: City) => ({
          ...item,
          label: item.name,
          value: item.id,
          isLeaf: false,
        }));
        Object.assign(targetOption, { children: childData.length ? childData : []});
        targetOption.loading = false;
        setData(data.concat([]));
      });
    } else if (length === 2) {
      // 二级联动
      const targetOption = selectedOptions[1];
      targetOption.loading = true;
      request({ url: `${api.findDistrictByCity}?id=${targetOption.value}` }).then((res: BaseData<District[]>) => {
        const childData = res.data.map((item) => ({
          ...item,
          label: item.name,
          value: item.id,
          isLeaf: true,
        }));
        Object.assign(targetOption, { children: childData.length ? childData : []});
        targetOption.loading = false;
        setData(data.concat([]));
      });
    }
  };

  const onChangeAddress = (value, selectedOptions) => {
    setAddress({
      provinceId: value[0],
      cityId: value[1],
      districtId: value[2],
    });
    props.onChange({
      provinceId: value[0],
      cityId: value[1],
      districtId: value[2],
      address: detail,
    });
  };

  const onChangeDetail = (e) => {
    setDetail(e.target.value);
    props.onChange({
      ...address,
      address: e.target.value,
    });
  };

  return (
    <>
      <Cascader
        allowClear={false}

        // @ts-ignore
        loadData={loadAddressData}
        onChange={onChangeAddress}
        options={data}
      />
      <Input
        className={styles.addressInput}
        onChange={onChangeDetail}
        placeholder="请输入详情地址"
      />
    </>
  );
});

export { Address };
