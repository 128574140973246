import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Cascader, Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

// 多档口打标签
const StallFormList = observer((props) => {
  const { loadMarketData, marketFloor } = props.store;

  return (
    <>
      <div className={styles.splitLine}/>
      <Form.Item
        label="档口名称"
        required
      >
        <Form.Item
          name={[
            'firstShop',
            'shopName',
          ]}
          noStyle
          rules={[
            {
              required: true,
              message: '请输入档口名称',
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="请输入"
          />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label="档口号"
        name={[
          'firstShop',
          'shopNo',
        ]}
        rules={[
          {
            required: true,
            message: '请输入档口号',
          },
        ]}
      >
        <Input
          autoComplete="off"
          placeholder="请输入"
        />
      </Form.Item>
      <Form.Item
        label="市场楼层"
        name={[
          'firstShop',
          'marketFloor',
        ]}
        rules={[
          {
            required: true,
            message: '请选择市场楼层',
          },
        ]}
      >
        <Cascader
          changeOnSelect
          loadData={loadMarketData}
          options={marketFloor}
        />
      </Form.Item>
      <Form.List
        name="stallInfo"
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div key={key}>
                <div className={styles.splitLine}/>
                <Form.Item
                  {...restField}
                  label="档口名称"
                  required
                >
                  <Form.Item
                    name={[
                      name,
                      'shopName',
                    ]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请输入档口名称',
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      placeholder="请输入"
                    />
                  </Form.Item>
                  {fields.length > 0 ? (
                    <MinusCircleOutlined
                      className={styles.deleteIcon}
                      onClick={() => remove(name)}
                    />
                  ) : null}
                </Form.Item>
                <Form.Item
                  label="档口号"
                  name={[
                    name,
                    'shopNo',
                  ]}
                  {...restField}
                  rules={[
                    {
                      required: true,
                      message: '请输入档口号',
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="请输入"
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label="市场楼层"
                  name={[
                    name,
                    'marketFloor',
                  ]}
                  rules={[
                    {
                      required: true,
                      message: '请选择市场楼层',
                    },
                  ]}
                >
                  <Cascader
                    changeOnSelect
                    loadData={loadMarketData}
                    options={marketFloor}
                  />
                </Form.Item>
              </div>
            ))}
            {fields.length < 4 && (
              <Form.Item
                className="norequired"
                label={' '}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
              >
                <Button
                  className={styles.addShopBtn}
                  onClick={() => add()}
                  type="dashed"
                >
                  添加档口
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
    </>
  );
});

export { StallFormList };
